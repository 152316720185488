<template>
  <div class="page-404">
    <div class="box">
      <b-icon
        icon="exclamation-triangle"
        class="has-text-danger"
        custom-size="fa-5x"
      ></b-icon>
      <h1 class="title is-1">404</h1>
      <p class="subtitle is-4">
        Данная страница не найдена.<br />Проверьте верно ли указан путь
      </p>
      <b-button size="is-medium" icon-left="arrow-left" tag="router-link" to="/"
        >Назад</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page404',
  created() {
    this.$store.commit('toggleLoader', false);
  },
};
</script>

<style lang="scss" scoped>
.page-404 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0 30px;
  text-align: center;

  .icon {
    margin-top: 30px;
  }

  h1 {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
</style>
